import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import queryString from "query-string"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, StaticQuery } from "gatsby"

const AuthenticatePage = ({ data }, location) => {
  const queryParams = queryString.parse(location.search)
  const [identifier, setIdentifier] = useState("")
  const [error, setError] = useState("")
  const [notFound, setNotFound] = useState(queryParams.notFound === "true")

  function handleSubmit(e) {
    e.preventDefault()
    if (!/^[a-zA-Z0-9]{4}$/.test(identifier)) {
      setError("Please enter a valid 4-digit alphanumeric code.")
      return
    }
    setError("")

    const capitalizedIdentifier = identifier.toUpperCase()
    navigate(`/piece/${capitalizedIdentifier}`)
  }

  return (
    <Layout title="Herschel Shapiro">
      <SEO title="Authenticate" />
      <article className="post-content page-template no-image">
      <div className="container my-5 post-content-body">
        <h2>Authenticate Your Artwork</h2>
        {notFound && (
          <div className="alert alert-warning" role="alert">
            The unique identifier was not found. It may take a while for recent purchases to be updated. Please try again or <Link to="/contact">contact</Link> me for assistance.
          </div>
        )}



        <p>
        Each of my sculptures has a unique four-digit code on its back, which links to a digital certificate of authenticity containing  specific details and photos about the artwork, providing verification of its authenticity.
        </p>



        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <label htmlFor="identifierInput">Enter Unique Identifier</label>
            <input
              type="text"
              className={`form-control${error ? " is-invalid" : ""}`}
              id="identifierInput"
              placeholder="6VNH"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
            />
            <div className="invalid-feedback">{error}</div>
          </div>
          <button type="submit" className="btn btn-primary">
            Authenticate
          </button>
        </form>

        <figure className="kg-card kg-image-card" >
          <GatsbyImage
            image={data.benchAccounting.childImageSharp.gatsbyImageData}
            className="kg-image"
          />
          <figcaption>The unique identifier is on the back under the signiture.</figcaption>
        </figure>
      </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "plaque.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1360)
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AuthenticatePage location={props.location} data={data} {...props} />
    )}
  />
)
